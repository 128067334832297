import React, {useState} from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import RequestInfoModal from '../../elements/requestInfoModal';
const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );
  const openModal = (e) => {
    e.preventDefault();
    setmodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setmodalactive(false);
  };
  const [ModalActive, setmodalactive] = useState(false);

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          {/* <Link to="#0">Contact us</Link> */}
          <a
              href="#0"
              onClick={openModal}
              
            >
              Contact us
            </a>
            <RequestInfoModal
              id="request-modal"
              show={ModalActive}
              handleClose={(e)=>closeModal(e)}
            />
        </li>
        {/* <li>
          <Link to="#0">About us</Link>
        </li> */}
        <li>
          <Link to="#0">FAQ's</Link>
        </li>
        <li>
          <Link to="#0">Support</Link>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;