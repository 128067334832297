import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
import videoPlaceholder from "./../../assets/images/video-placeholder.jpg";
import TennesseeCourtlogo from "./../../assets/images/Tennessee-Court-logo.png";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const valueChanges = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              <span className="text-color-primary">DASH</span>, A decentralized
              Legal Collaboration System
            </h1>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Dash is not a product, it is a whole new way to think about how
                we manage, execute and achieve society's optimal expectations of
                legal services.
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <a
                    href="https://app.dash.law/#/register/individual"
                    rel="noreferrer"
                    target="_blank"
                    className="button button-primary button-wide-mobile"
                  >
                    Individuals
                  </a>

                  <a
                    href="https://app.dash.law/#/register/entity"
                    rel="noreferrer"
                    target="_blank"
                    className="button button-dark button-wide-mobile"
                  >
                    Firms
                  </a>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div
            className="reveal-from-bottom tennessee-logo"
            data-reveal-delay="1000"
          >
            <Image
              className="has-shadow"
              src={TennesseeCourtlogo}
              alt="Hero"
              width={250}
            />
            TN Access To Justice Campaign
            <div className="mt-16 mb-32">
              <label className='form-checkbox form-checkbox'>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => valueChanges(e)}
                />
                I agree to the terms and conditions
              </label>
        <span className={isChecked?'':'disabled-wrapper'}>
              <a
                href="https://www.tncourts.gov/"
                rel="noreferrer"
                target="_blank"
                disabled={!isChecked}
                className="button button-primary button-sm button-join-tennessee button-wide-mobile ml-32"
              >
                Join
              </a></span>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            <a
              data-video="https://www.youtube.com/embed/vDatKx-9Iqs"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={videoPlaceholder}
                alt="Hero"
                width={896}
                height={504}
              />
            </a>
          </div>
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://www.youtube.com/embed/vDatKx-9Iqs"
            videoTag="iframe"
          />
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
