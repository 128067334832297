import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import emailjs from "emailjs-com";
import Input from "../elements/Input";
import Button from "../elements/Button";
import { toast } from "react-toastify";
import validator from "validator";
const propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  closeHidden: PropTypes.bool,
};

const defaultProps = {
  children: null,
  show: false,
  closeHidden: false,
};

const RequestInfoModal = ({
  className,
  children,
  handleClose,
  show,
  closeHidden,
  ...props
}) => {
  const IntialInputs = () => ({
    inputs: {
      name: "",
      email: "",
      subject: "",
      body: "",
    },
  });
  const handleChange = (e) => {
    const { value, name } = e.target;
    const { inputs } = state;

    inputs[name] = value;
    setState({
      ...state,
      inputs,
    });
  };
  const [state, setState] = useState(IntialInputs());
  const [sendClicked, setSendClicked] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  
  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", stopProgagation);
    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", stopProgagation);
    };
  });

  useEffect(() => {
    handleBodyClass();
  }, [props.show]);

  const handleBodyClass = () => {
    if (document.querySelectorAll(".modal.is-active").length) {
      document.body.classList.add("modal-is-active");
    } else {
      document.body.classList.remove("modal-is-active");
    }
  };

  const keyPress = (e) => {
    e.keyCode === 27 && handleClose(e);
  };

  const stopProgagation = (e) => {
    e.stopPropagation();
  };

  const classes = classNames(
    "modal",
    show && "is-active",
    "modal-request",
    className
  );

  const sendEmail = async (e) => {
    e.preventDefault();
    setShowErrors(true)
    if (
      state.inputs.name!== ''&&
      state.inputs.email!== ''&&
      state.inputs.subject!== ''&&
      state.inputs.body !== '' 
    ) {
    setSendClicked(true);
    const form = document.querySelector("#infoRequest-form");
    console.log(form);
    try {
      // const templateParams = {
      //   name: state.inputs.name,
      //   email: state.inputs.email,
      //   subject: state.inputs.subject,
      //   message: state.inputs.body,
      // };
      // await emailjs.sendForm(
      //   process.env.REACT_APP_SERVICE_ID,
      //   process.env.REACT_APP_TEMPLATE_ID,
      //   form,
      //   process.env.REACT_APP_USER_ID
      // );
      // const requestOptions = {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({
      //     templateObj: { sendToMail: "toqa.hagag12@gmail.com" },
      //   }),
      // };
      // fetch(
      //   "https://szpa2kktch.execute-api.us-east-2.amazonaws.com/dev/sendRequestInfo",
      //   requestOptions
      // )
      //   .then((response) => response.json())
      //   .then((data) => {
      //     handleCloseModal(e);
      //     toast.success("Your email sent successfully");
      //   });
        const mailto = `mailto:larry@legalalignment.com?subject=New Information Request from ${state.inputs.name}&body=Dear Dash Team:%0D%0A%0D%0AHello, ${state.inputs.name} would like to know more information .%0D%0A%0D%0AAdditional notes:%0D%0A
        (Please type your notes here)%0D%0A%0D%0AName: ${state.inputs.name}%0D%0AEmail: ${state.inputs.email}%0D%0AMessage: ${state.inputs.body}%0D%0A%0D%0A%0D%0A%0D%0AMany thanks.%0D%0AWith kind regards,`;
        window.location.href = mailto;
        toast.success("Your email sent successfully");
        setSendClicked(false);
        setState(IntialInputs());
        setShowErrors(false);
        handleClose(e);
      
    } catch (e) {
      console.log(e);
    }
  }
}
const handleCloseModal=(e)=>{
  setSendClicked(false);
  setShowErrors(false);
  handleClose(e);
  setState(IntialInputs);
}
  return (
    <>
      {show && (
        <div {...props} className={classes}>
          <div
            className="modal-inner request-info-modal"
            onClick={stopProgagation}
          >  
           <h3>Request Information</h3>
            {!closeHidden && (
              <button
                className="modal-close"
                aria-label="close"
                onClick={(e)=>handleCloseModal(e)}
              ></button>
            )}
            <div className="modal-content pt-0">
           
              <form id="infoRequest-form">
                <Input
                  id="name"
                  type="text"
                  label="Name"
                  hasIcon="right"
                  placeholder="Your name"
                  name="name"
                  changeFunction={(e) => {
                    handleChange(e);
                  }}
                  value={state.inputs.name}
                ></Input>
                {showErrors === true &&
                  validator.isEmpty(state.inputs.name) && (
                    <div className="dash-error-message">Required</div>
                  )}
                <Input
                  id="email"
                  type="email"
                  label="Email"
                  hasIcon="right"
                  placeholder="Your best email"
                  name="email"
                  changeFunction={(e) => {
                    handleChange(e);
                  }}
                  value={state.inputs.email}
                ></Input>
                {showErrors === true &&
                  validator.isEmpty(state.inputs.email) && (
                    <div className="dash-error-message">Required</div>
                  )}
                {showErrors === true &&
                 !validator.isEmpty(state.inputs.email)&& !validator.isEmail(state.inputs.email) && (
                    <div className="dash-error-message">
                      Email formate isn't correct
                    </div>
                  )}
                <Input
                  id="mailSubject"
                  type="text"
                  label="Subject"
                  hasIcon="right"
                  placeholder="Email Subject"
                  name="subject"
                  changeFunction={(e) => {
                    handleChange(e);
                  }}
                  value={state.inputs.subject}
                ></Input>
                {showErrors === true &&
                  validator.isEmpty(state.inputs.subject) && (
                    <div className="dash-error-message">Required</div>
                  )}
                <Input
                  id="mailBody"
                  type="textarea"
                  label="Body"
                  hasIcon="right"
                  placeholder="Email Body"
                  changeFunction={(e) => {
                    handleChange(e);
                  }}
                  name="body"
                  value={state.inputs.body}
                ></Input>
                {showErrors === true &&
                  validator.isEmpty(state.inputs.body) && (
                    <div className="dash-error-message">Required</div>
                  )}
                <Button
                  type="button"
                  onClick={sendEmail}
                  className="button button-primary button-wide-mobile"
                  loading={sendClicked}
                  disabled={sendClicked}
                >
                  Send
                </Button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

RequestInfoModal.propTypes = propTypes;
RequestInfoModal.defaultProps = defaultProps;

export default RequestInfoModal;
