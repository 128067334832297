import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import feature from './../../assets/images/feature-tile-icon-01.svg'
import feature2 from './../../assets/images/feature-tile-icon-02.svg'
import feature3 from './../../assets/images/feature-tile-icon-03.svg'
import feature4 from './../../assets/images/feature-tile-icon-04.svg'
import feature5 from './../../assets/images/feature-tile-icon-05.svg'
//import feature6 from './../../assets/images/feature-tile-icon-06.svg'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Collaborating Across the Legal Ecosystem',
    paragraph: 'DASH is a an inteligent legal system tool for lawyers, clients, legal teams, courts, witnesses, expertise and all participants in any legal represenation providing immediate access to permission based real time information about their matter.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={feature}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  Transparency
                    </h4>
                  <p className="m-0 text-sm">
                  DASH allows the client and lawyer to agree on the degree to which the legal team, the project manager and the client have access to real time informaiton about the status of the legal matter.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={feature2}
                      alt="Features tile icon 02"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  Price Predictability
                    </h4>
                  <p className="m-0 text-sm">
                  Corporate cients expect price predictability when dealing with outside counsel. DASH uses legal matter templates to permit lawyers to quickly estimate a price for the legal matters they customarily represent.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={feature3}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  AI Process Improvement
                    </h4>
                  <p className="m-0 text-sm">
                  The analytical power and predictibility of Artificial Intelligence fuel the process improvement capacity of DASH. Users will have instant awareness of anticipated budget scope crep, looming deadlines and optimal utlization of their legal teams.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={feature4}
                      alt="Features tile icon 04"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  NFT Funding
                    </h4>
                  <p className="m-0 text-sm">
                  The secure blockchain revenue enhancing non-fungible token technology is built into the infrastructure of DASH. Charitable access to justice and litigation funding capabilities are a part of the DASH ecosystem.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={feature5}
                      alt="Features tile icon 05"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  Robust Interoperability
                    </h4>
                  <p className="m-0 text-sm">
                  DASH is a hub for legaltech integrations with each lawyer's most frequently used technology. Instant two way data transfer assures the legal team that the single signon features of DASH provides access to the numerous legal technologies lawyers depend on in a single DASHboard.
                    </p>
                </div>
              </div>
            </div>

            {/* <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={feature6}
                      alt="Features tile icon 06"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  Lawyers Just Practicing Law
                    </h4>
                  <p className="m-0 text-sm">
                  Lawyers didn't go to law school to spend up to 25% of their day doing administrivia (I.e. time and billing,  project planning and execution, budget reconciliation and managing task performance. DASH does that for you, allowing you to spend more time practicing law.
                    </p>
                </div> 
              </div>
            </div> */}

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;