import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import RequestInfoModal from "../elements/requestInfoModal";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const [ModalActive, setmodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setmodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setmodalactive(false);
  };
  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );



  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="cta-slogan">
            <h3 className="m-0">Learn more about DASH or requst a demo?</h3>
          </div>
          <div className="cta-action">
            <a
              href="#0"
              onClick={openModal}
              className="button button-dark button-wide-mobile"
            >
              Request Information
            </a>
            <RequestInfoModal
              id="request-modal"
              show={ModalActive}
              handleClose={(e)=>closeModal(e)}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
