import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import featuresSplit from './../../assets/images/features-split-image-01.png'
import featuresSplit2 from './../../assets/images/features-split-image-02.png'
//import featuresSplit3 from './../../assets/images/features-split-image-03.png'
const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Lawyers Just Practicing Law',
    paragraph: `Lawyers didn't go to law school to spend up to 25% of their day doing administrivia (I.e. time and billing,  project planning and execution, budget reconciliation and managing task performance). DASH does that for you, allowing you to spend more time practicing law.`
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div>
                <h3 className="mt-0 mb-12">
                Digital Transformation of Law
                  </h3>
                <p className="m-0">
                Like all other industries, law is being transformed into a technology business. Harnessing the power of the data generated by a legal practice means that lawyers can provide legal services "better, faster, cheaper" and more profitably. Collaboration among team members and clients becomes the norm as faciliated by the real time communication capabilities of DASH. Templates for any legal matter type empower real time project planning, execution and after action reviews with only small adjustments for the diffetences in each unique matters 
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={featuresSplit}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div>
                  <h3 className="mt-0 mb-12">
                Instantly Updated Views of Upcoming and Overdue Tasks
                  </h3>
                <p className="m-0">
                Using Lean methodolgies, DASH visualizes tasks to be perform in a variety of useful formats (KanBan, lists, calendar, status, financial performance and deadlines). Prioritizing tasks in terms of due dates and financial performance gives lawyers and clients a real time update on all tasks in all legal matters instantly.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={featuresSplit2}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            {/* <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div>
                <h3 className="mt-0 mb-12">
                Instantly Updated Views of Upcoming and Overdue Tasks
                  </h3>
                <p className="m-0">
                Using Lean methodolgies, DASH visualizes tasks to be perform in a variety of useful formats (KanBan, lists, calendar, status, financial performance and deadlines). Prioritizing tasks in terms of due dates and financial performance gives lawyers and clients a real time update on all tasks in all legal matters instantly.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={featuresSplit3}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div> */}

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;